import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, useEffect, useState } from 'react';
import { backendOptions, I18nextProvider, useI18n } from '@octagon/i18n';
import { AEM_CONFIG, logErrCode, logErrDetails, logLabel } from '../../constants/constants';
import { logMessage } from "../../utils/Logger";
export const OctagonI18nProvider = ({ children, }) => {
    const [error, setError] = useState(null);
    // i18n instance initialization
    const { i18n } = useI18n({
        backend: backendOptions({
            backendUrl: AEM_CONFIG.BACKEND_URL,
            postProcess: true,
        }),
        lng: AEM_CONFIG.FALLBACK_LAN,
        debug: false,
    });
    // Error handling:
    useEffect(() => {
        const handleFailedLoading = (lng, ns, msg) => {
            const errMsg = `Failed to load "${lng}" content for "${ns}" namespace. Error: "${msg}" `;
            setError(new Error(errMsg));
        };
        i18n.on('failedLoading', handleFailedLoading);
        return () => i18n.off('failedLoading', handleFailedLoading);
    }, [i18n]);
    if (error) {
        logMessage(logLabel.error, logErrCode.aemErr, logErrDetails.aemErr + error.message);
        throw new Error(error.message);
    }
    return (_jsx(Suspense, { fallback: _jsx("p", {}), children: _jsx(I18nextProvider, { i18n: i18n, children: children }) }));
};
