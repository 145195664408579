import { jsx as _jsx } from "react/jsx-runtime";
// Add any root level React Providers here
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from './store';
import { OctagonI18nProvider } from './lib/i18n/OctagonI18nProvider';
const queryClient = new QueryClient();
function AppProviders({ children }) {
    return (_jsx(QueryClientProvider, { client: queryClient, children: _jsx(OctagonI18nProvider, { children: _jsx(Provider, { store: store, children: children }) }) }));
}
export default AppProviders;
