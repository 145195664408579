import { FETCH_DATA_ERROR, FETCH_DATA_SUCCESS, FETCH_NO_DATA } from '../../actions/types';
const initialState = {
    data: [],
    error: null,
};
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
            };
        case FETCH_NO_DATA:
            return {
                ...state,
                data: null,
            };
        case FETCH_DATA_ERROR:
            return {
                ...state,
                data: null,
                error: action.payload,
            };
        default:
            return state;
    }
};
// @ts-ignore
export default dataReducer;
