import axios from '@octagon/axios';
import { logErrDetails, logLabel, onlineProfileIdentifier, API_URLS } from "../constants/constants";
import { getEnvironment } from "./Utils";
export const logMessage = (level, errorCode, message, isConsoleLog = false) => {
    if (isConsoleLog) {
        console.warn('React Error Boundary >>', level, errorCode, message);
        return;
    }
    try {
        if (!getEnvironment().isLocal) {
            axios.post(API_URLS.Logger, { level, errorCode, errorLog: message, onlineProfileIdentifier: onlineProfileIdentifier }, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
        }
    }
    catch (err) {
        let errorMessage = err.message ? err.message : "";
        errorMessage += err.response.data && err.response.data.Message ? err.response.data.Message : "";
        logMessage(logLabel.error, err.response.status, logErrDetails.logger + errorMessage);
    }
};
